
import { useProfileStore } from '@/store/profile.module';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { userService } from '@/services/UserService';
import { ServiceError } from '@/services/util/ServiceError';
declare let zE: any;

export default defineComponent({
  emits: ['menu-button-click', 'topbar-user-menu', 'logout-button-click'],
  props: {
    topbarUserMenuActive: Boolean,
  },
  setup(_props, { emit }) {
    const storeProfile = useProfileStore();
    const items = ref([]);
    const userProfile = ref();
    const avatarPlaceholder = window.location.origin + '/assets/layout/images/avatar.svg';
    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isSaasUser = computed(() => storeProfile.isSaasUser);
    onMounted(async () => {
      const result = await userService.findSelf();
      if (!(result instanceof ServiceError)) {
        userProfile.value = result;
      }
    });

    const onMenuButtonClick = function (event: any) {
      emit('menu-button-click', event);
    };
    const onTopbarUserMenuButtonClick = function (event: any) {
      emit('topbar-user-menu', event);
    };
    const onLogoutButtonClick = function (event: any) {
      emit('logout-button-click', event);
    };

    const showSupport = () => {
      zE('messenger', 'show');
      zE('messenger', 'open');
    };

    const logoCommunityUrl = ref(window.location.origin + '/assets/layout/images/icon-community.svg');

    return {
      storeProfile,
      items,
      isAdminUser,
      avatarPlaceholder,
      userProfile,
      onMenuButtonClick,
      showSupport,
      onTopbarUserMenuButtonClick,
      onLogoutButtonClick,
      isSaasUser,
      logoCommunityUrl,
    };
  },
});
