import i18n from '@/i18n';
import { DateTime, Settings } from 'luxon';
import { useProfileStore } from '@/store/profile.module';

const moment = require('moment-timezone');

const dateFormat = {
  formatCalendar(dateStringUTC: string): string {
    const storeProfile = useProfileStore();
    if (!dateStringUTC) return '';
    //define default timezone in Luxon and languaje in momentjs
    moment.locale(i18n.global.locale.value);
    moment.tz.defaultZone = storeProfile.timezone;
    const tzDate = this.formatTimezoneISO(dateStringUTC);
    return moment(tzDate).calendar();
  },

  formatDateAndHour(dateStringUTC: string): string {
    if (!dateStringUTC) {
      return '';
    }
    const dateUTC = new Date(dateStringUTC);

    const dateLocale = dateUTC
      .toLocaleDateString(i18n.global.locale.value, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .toString();
    const timeLocale = dateUTC
      .toLocaleTimeString(i18n.global.locale.value, {
        hour: '2-digit',
        minute: '2-digit',
      })
      .toString();

    return `${dateLocale} - ${timeLocale}h`;
  },

  dateFormat() {
    if (i18n.global.locale.value == 'es') {
      return 'dd/mm/yy';
    } else {
      return 'mm/dd/yy';
    }
  },

  /**
   * Returns the simple format of the date depending on the locale format
   * @returns The format of the date
   */
  getDateSimpleFormat() {
    if (i18n.global.locale.value == 'es') {
      return 'DD-MM-YYYY';
    } else {
      return 'MM-DD-YYYY';
    }
  },

  formatTimezoneISO(dateStringUTC: string) {
    const storeProfile = useProfileStore();
    //define default timezone in Luxon to UTC as it is input date zone
    Settings.defaultZone = 'UTC';
    const luxonDate = DateTime.fromISO(dateStringUTC);
    return luxonDate.setZone(storeProfile.timezone).toISO();
  },

  formatLocalTimezone(dateStringUTC: string) {
    const dateUTC = new Date(dateStringUTC);
    const dateLocalTimezone = new Date();
    dateLocalTimezone.setTime(dateUTC.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
    return dateLocalTimezone.toISOString();
  },

  calculateAge(dateString: any) {
    const a = moment();
    const b = moment(dateString);
    return a.diff(b, 'years'); // calculates patient's age in years
  },

  /**
   *  Given a date it returns the same it returns the same date in ISO format
   * @param date
   * @returns The date in ISO format
   */
  getIsoFormat(date: string) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  },

  /**
   * Given a date it returns the same day with 00:00:00 hours
   * @param date
   * @returns the date of the same day with 00:00:00 hours
   */
  startOfCertainDay(date: string) {
    return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  },

  /**
   * Given a date it returns the same day with 23:59:59 hours
   * @param date
   * @returns the date of the same day with 23:59:59 hours
   */
  endOfCertainDay(date: string) {
    return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
  },

  /**
   * Given a date it returns the date with moment format 'MMMM D, h:mm a'
   * @param date
   * @returns the date with format 'MMMM D, h:mm a'
   */
  formatDateWithHour(date: string): string {
    moment.locale(i18n.global.locale.value);
    return moment(date).format('MMMM D, h:mm a');
  },

  /**
   * Given a date it returns the hour with moment format 'H:mm' (24h format)
   * @param date
   * @returns the hour with moment format 'H:mm' (24h format)
   */
  formatDateOnlyHour24h(date: string): string {
    moment.locale(i18n.global.locale.value);
    return moment(date).format('H:mm A');
  },

  /**
   * Given a date it returns the hour with moment format 'H:mm:ss' (24h format)
   * @param date
   * @returns the hour with moment format 'H:mm:ss' (24h format)
   */
  formatDateOnlyHourWithSeconds(date: string): string {
    moment.locale(i18n.global.locale.value);
    return moment(date).format('H:mm:ss');
  },

  /**
   * Given a date in locale ISO string it returns the same date in UTC ISO string format
   * @param date date in ISO string
   * @returns the date in UTC ISO string format
   */
  userTimezoneToUTC(dateString: string) {
    return moment(dateString).utc().format('YYYY-MM-DDTHH:mm:ss');
  },

  /**
   * Given a UTC date in ISO string it returns the same date in user local timezone in ISO string format
   * @param date UTC date in ISO string
   * @returns the date in user local timezone in ISO string format
   */
  utcToUserTimezone(dateStringUTC: string) {
    return moment.utc(dateStringUTC).local().format('YYYY-MM-DDTHH:mm:ss');
  },

  /**
   * Given a UTC date in ISO string it returns the same date in the specific timezone in ISO string format
   * @param date
   * @param timeZone
   * @returns the date in the specific timezone in ISO string format
   */
  utcToTimezone(dateStringUTC: string, timeZone: string | undefined) {
    if (!timeZone) {
      return dateStringUTC;
    }
    return moment.utc(dateStringUTC)?.tz(timeZone)?.format('YYYY-MM-DDTHH:mm:ss');
  },

  /**
   * Given date in the specific timezone in ISO string format it returns the same date in a UTC date in ISO string
   * @param date
   * @param timeZone
   * @returns the date in the specific timezone in ISO string format
   */

  timezoneToUtc(dateStringUTC: string, timeZone: string | undefined) {
    if (!timeZone) {
      return dateStringUTC;
    }
    return moment.tz(dateStringUTC, 'YYYY-MM-DDTHH:mm:ss', timeZone).utc().format('YYYY-MM-DDTHH:mm:ss');
  },

  /**
   * Given a UTC date in ISO string it returns the same date in the specific timezone in ISO string format
   * @param date
   * @returns the date in the specific timezone
   */

  utcToTimezoneObject(dateStringUTC: string, timeZone: string) {
    if (!timeZone) {
      return dateStringUTC;
    }
    return moment.utc(dateStringUTC).tz(timeZone);
  },

  /**
   * Given a date it returns the date with format dd/mm/yyyy
   * @param date
   * @returns the date with format dd/mm/yyyy
   */
  formatDate(date: string | undefined): string {
    if (!date) {
      return '';
    }
    const newDate = new Date(date);
    moment.locale(i18n.global.locale.value);
    return `${moment(newDate).format('L')}`;
  },

  /**
   * Given a date string it returns the number value of the date according to universal value
   * @param date string of the date or undefined
   * @returns the number value of the date according to universal value
   */
  getTimeFromDateString(date: string | undefined): number {
    if (!date) {
      return 0;
    }
    return new Date(date).getTime();
  },
};

export default dateFormat;
