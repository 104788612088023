
import { defineComponent, ref } from 'vue';
import { version } from '../../package.json';
import Dialog from 'primevue/dialog';
import CertificateCE from '@/components/CertificateCE.vue';
export default defineComponent({
  components: { Dialog, CertificateCE },
  setup() {
    const showDialog = ref(false);
    const openDialog = () => {
      showDialog.value = true;
    };
    const logoTextUrl = ref(window.location.origin + '/assets/layout/images/logo-text.svg');
    return { showDialog, openDialog, version, logoTextUrl };
  },
});
