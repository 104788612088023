import { defineStore } from 'pinia';
import { User, UserFormDto, UserRole } from '@/models/User';
import { userService } from '@/services/UserService';
import { accountService } from '@/services/AccountService';
import { ServiceError } from '@/services/util/ServiceError';
import jwtDecode from 'jwt-decode';
import i18n from '@/i18n';
import Account from '@/models/Account';
import mitt from 'mitt';

export const emitter = mitt();

const CURRENT_ACCOUNT_KEY = 'currentAccountContext';
const AUTH_TOKEN_KEY = 'authToken';

export const useProfileStore = defineStore('profile-store', {
  state: () => ({
    authUserToken: localStorage.getItem(AUTH_TOKEN_KEY) ?? '',
    profile: undefined as User | undefined,
    userAccounts: [] as Account[],
    currentAccountContext: undefined as any | undefined,
  }),
  getters: {
    authToken: (state) => state.authUserToken,
    externalId: (state) => {
      return getExternalIdFromToken(state.authUserToken);
    },
    userId: (state) => {
      return getDataFromToken(state.authUserToken, `${process.env.VUE_APP_AUTH0_API}userId`);
    },
    specialistId: (state) => {
      return state.currentAccountContext?.professionalId;
    },
    accountId: (state) => {
      return state.currentAccountContext?.accountId;
    },
    isAdmin: (state) => hasRole(state.currentAccountContext, UserRole.ADMIN),
    isReceptionist: (state) => hasRole(state.currentAccountContext, UserRole.RECEPTIONIST),
    isSaasUser: (state) =>
      hasRole(state.currentAccountContext, UserRole.ADMIN) ||
      hasRole(state.currentAccountContext, UserRole.RECEPTIONIST) ||
      hasRole(state.currentAccountContext, UserRole.SPECIALIST),
    isSpecialist: (state) => hasRole(state.currentAccountContext, UserRole.SPECIALIST),
    userRoles: (state) => {
      return state.currentAccountContext?.roles || [];
    },
    timezone: (state) => {
      return getDataFromToken(state.authUserToken, 'timezone');
    },
    language: (state) => {
      return getDataFromToken(state.authUserToken, 'language');
    },
    getProfile: (state) => state.profile,
    accountContexts: (state) => {
      return getAccountContexts(decodeToken(state.authUserToken)) || [];
    },
  },
  actions: {
    async saveTokenAndProfile(token: string) {
      this.authUserToken = token;
      localStorage.setItem(AUTH_TOKEN_KEY, token);

      const decodedToken = decodeToken(token);
      const accountContexts = getAccountContexts(decodedToken);
      if (accountContexts && accountContexts.length > 0) {
        const savedAccountId = localStorage.getItem(CURRENT_ACCOUNT_KEY);
        const savedContext = accountContexts.find((context) => context.accountId === savedAccountId);
        this.setCurrentAccountContext(savedContext ? savedContext.accountId : accountContexts[0].accountId);
      }

      await this.fetchProfile();
      await this.fetchUserAccounts();
    },

    deleteTokenAndProfile() {
      this.authUserToken = '';
      this.profile = undefined;
      this.currentAccountContext = undefined;
      localStorage.removeItem(CURRENT_ACCOUNT_KEY);
      localStorage.removeItem(AUTH_TOKEN_KEY);
    },

    async fetchProfile() {
      const response = await userService.findSelf();
      if (!(response instanceof ServiceError)) {
        this.profile = response;
        i18n.global.locale.value = response.language;
      }
      return response;
    },

    async modifyProfile(user: UserFormDto) {
      const response = await userService.modifySelf(user);
      if (!(response instanceof ServiceError)) {
        this.profile = response;
      }
      return response;
    },

    async fetchUserAccounts() {
      this.userAccounts = [];
      const response = await accountService.getAccounts();
      if (!(response instanceof ServiceError)) {
        this.userAccounts = response;
      }
      return response;
    },

    setCurrentAccountContext(accountId: string) {
      const newContext = this.accountContexts.find((context) => context.accountId === accountId);
      if (newContext) {
        this.currentAccountContext = newContext;
        localStorage.setItem(CURRENT_ACCOUNT_KEY, accountId);
        emitter.emit('accountChanged', accountId);
      } else {
        console.error(`No account context found for accountId: ${accountId}`);
      }
    },

    loadPersistedAccount() {
      const savedAccountId = localStorage.getItem(CURRENT_ACCOUNT_KEY);
      if (savedAccountId && this.authUserToken) {
        this.setCurrentAccountContext(savedAccountId);
      }
    },

    initializeStore() {
      if (this.authUserToken) {
        this.loadPersistedAccount();
        this.fetchProfile();
        this.fetchUserAccounts();
      }
    },
  },
});

function hasRole(accountContext: any, role: UserRole): boolean {
  return accountContext?.roles.includes(role) || false;
}

function getDataFromToken(token: string, dataKey: string): any {
  const decodedToken = decodeToken(token);
  return decodedToken?.[dataKey];
}

function getExternalIdFromToken(token: string): string | undefined {
  const decodedToken = decodeToken(token);
  return decodedToken?.sub ? encodeURIComponent(decodedToken.sub) : undefined;
}

function decodeToken(token: string): any {
  if (!token) return undefined;
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error('Failed to decode token:', error);
    return undefined;
  }
}

function getAccountContexts(decodedToken: any): any[] | undefined {
  return decodedToken?.[`${process.env.VUE_APP_AUTH0_API}accountContexts`];
}
