import router from '@/router';
import axios from 'axios';
import { ServiceError } from './ServiceError';

async function handleError(error: unknown): Promise<ServiceError> {
  if (axios.isAxiosError(error)) {
    if (error.response && error.response?.status == 403) {
      await router.replace(`/error/no-grants`);
    }
    return new ServiceError(error.message, error.response?.data.code, error.response?.status);
  } else {
    return new ServiceError('UNKNOWN');
  }
}

export default handleError;
