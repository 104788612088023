import { createApp, h, reactive } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';

/* internationalization*/
import i18n from './i18n';

/* primevue*/
import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InlineMessage from 'primevue/inlinemessage';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
import Image from 'primevue/image';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import VueApexCharts from 'vue3-apexcharts';
import moment from 'moment';

/* autentication*/
import { Auth0 } from './auth';
const pinia = createPinia();

const app = createApp({
  computed: {
    ViewComponent() {
      return App;
    },
  },
  render() {
    return h(this.ViewComponent);
  },
});

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });

app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(i18n);
app.use(pinia);
app.use(VueApexCharts);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Apexchart', VueApexCharts);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('InlineMessage', InlineMessage);
app.component('Listbox', Listbox);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Password', Password);
app.component('RadioButton', RadioButton);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('Image', Image);

import { FilterService } from 'primevue/api';
if (FilterService.register) {
  FilterService.register('filterEqualDate', (a: string, b: Date) => {
    if (!b) {
      return true;
    }

    const localTimezoneA = new Date(a);
    localTimezoneA.setTime(localTimezoneA.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
    const filterDayA = localTimezoneA.getDate();
    const filterMonthA = localTimezoneA.getMonth() + 1;
    const filterYearA = localTimezoneA.getFullYear();
    const filterDayB = b.getDate();
    const filterMonthB = b.getMonth() + 1;
    const filterYearB = b.getFullYear();

    return filterDayA == filterDayB && filterMonthA == filterMonthB && filterYearA == filterYearB;
  });
}

async function init() {
  const AuthPlugin = await Auth0.init({
    onRedirectCallback: (appState: any) => {
      router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    },
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID as string,
    domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
    redirectUri: process.env.VUE_APP_BASE_URL as string,
  });

  if (i18n.global.locale.value == 'es') {
    const messages = await import('./locales/es.json');
    app.use(PrimeVue, { ripple: true, locale: messages.primevue });
  } else {
    const messages = await import('./locales/en.json');
    app.use(PrimeVue, { ripple: true, locale: messages.primevue });
  }

  moment.updateLocale('es', { weekdaysMin: 'D_L_M_X_J_V_S'.split('_') });
  moment.updateLocale('en', { weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_') });

  app.use(AuthPlugin);
  app.mount('#app');
}

init();
