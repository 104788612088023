import { User, UserFormDto, UserRole, UserTableDto } from '@/models/User';
import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';
import { UserReassign } from '@/models/UserReassign';
import { LoginAuditLog } from '@/models/AuditLog';

let axiosInstance: AxiosInstance;
let storeProfile: any;

class UserService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async findSelf(): Promise<User | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      if (storeProfile.accountId == null) throw new Error('Account Id is null');
      else {
        const networkResponse = await axiosInstance.get(
          `${process.env.VUE_APP_API_USER_SERVICE}/self/user?accountId=${storeProfile.accountId}`,
        );
        return networkResponse.data;
      }
    } catch (error) {
      return handleError(error);
    }
  }

  async find(userId: string): Promise<User | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_USER_SERVICE}/user/${userId}?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
  async findAll(roles?: UserRole[]): Promise<UserTableDto[] | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    let params = {};

    if (roles && roles.length > 0) {
      const stringRoles = roles.map((role) => role as string);
      params = {
        roles: stringRoles,
      };
    }
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_USER_SERVICE}/user?accountId=${storeProfile.accountId}`,
        {
          params: params,
          paramsSerializer: (p: any) => {
            return qs.stringify(p, { arrayFormat: 'repeat' });
          },
        },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findAllFromIds(idList: string[]): Promise<UserTableDto[] | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_USER_SERVICE}/user?accountId=${storeProfile.accountId}`,
        {
          params: { professionalIds: idList },
        },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async createUser(data: UserFormDto): Promise<User | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_USER_SERVICE}/user?accountId=${storeProfile.accountId}`,
        data,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async modifySelf(data: UserFormDto): Promise<User | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_USER_SERVICE}/self/user?accountId=${storeProfile.accountId}`,
        data,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async modify(userId: string, data: UserFormDto): Promise<User | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_USER_SERVICE}/user/${userId}?accountId=${storeProfile.accountId}`,
        data,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async deleteProfessionalByUserId(userId: string, userReassign: UserReassign): Promise<void | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.delete(
        `${process.env.VUE_APP_API_USER_SERVICE}/user/${userId}/professional?accountId=${storeProfile.accountId}`,
        {
          data: userReassign,
        },
      );
      return networkResponse.data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async findAllLoginAuditLog(): Promise<LoginAuditLog[] | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_USER_SERVICE}/auditlogs?accountId=${storeProfile.accountId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
}
export const userService = new UserService();
