import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-about__container" }
const _hoisted_2 = { class: "c-about__container__name" }
const _hoisted_3 = { class: "c-about__container__version" }
const _hoisted_4 = { class: "c-about__container__description" }
const _hoisted_5 = { href: "https://www.joinhealz.com/legales/licencia-software" }
const _hoisted_6 = {
  href: "https://www.joinhealz.com/legales/terminos-condiciones",
  target: "_blank"
}
const _hoisted_7 = {
  href: "https://www.joinhealz.com/legales/politica-privacidad",
  target: "_blank"
}
const _hoisted_8 = {
  href: "https://www.joinhealz.com/legales/terminos-condiciones-contratacion",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CertificateCE = _resolveComponent("CertificateCE")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
    modal: true,
    class: "c-about",
    style: _normalizeStyle('--image:url(' + _ctx.logoTextUrl + ');')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('about-us.name')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('about-us.name-pronunciation')), 1)
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('about-us.version', { version: _ctx.version })), 1),
        _createElementVNode("p", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('about-us.description-part-1')) + " ", 1),
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('about-us.description-part-2')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('about-us.description-part-3')), 1)
        ]),
        _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('about-us.conditions')), 1),
        _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('about-us.privacy-policy')), 1),
        _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('about-us.recruitment-policy')), 1)
      ]),
      _createVNode(_component_CertificateCE),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('about-us.footer')), 1)
    ]),
    _: 1
  }, 8, ["visible", "style"]))
}