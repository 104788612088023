enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  SPECIALIST = 'SPECIALIST',
  PATIENT = 'PATIENT',
  RECEPTIONIST = 'RECEPTIONIST',
}

enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export default interface User {
  birthdate: string;
  creationDate: string;
  deleted: boolean;
  email: string;
  externalId: string;
  gender: Gender;
  id: string;
  language: string;
  meetingReason: string;
  modificationDate: string;
  name: string;
  notifications: Record<string, unknown>[];
  phone: string;
  picture: string;
  postalCode: string;
  privacyDate: string;
  roles: UserRole[];
  root: boolean;
  surname: string;
  timezone: string;
  useLicenseDate: string;
}

interface UserTableDto {
  email: string;
  id: string;
  name: string;
  phone: string;
  picture: string;
  professionalId: string;
  roles: UserRole[];
  surname: string;
  permission: string;
}

class UserFormDto {
  email: string;
  language: string;
  name: string;
  roles: UserRole[];
  surname: string;
  timezone: string;

  constructor(user: User) {
    this.email = user.email;
    this.language = user.language;
    this.name = user.name;
    this.roles = user.roles;
    this.surname = user.surname;
    this.timezone = user.timezone;
  }
}

export function updateUserFromUserFormDto(user: User, userformDto: UserFormDto): User {
  const newUser = Object.assign({}, user);
  newUser.email = userformDto.email;
  newUser.language = userformDto.language;
  newUser.name = userformDto.name;
  newUser.roles = userformDto.roles;
  newUser.surname = userformDto.surname;
  newUser.timezone = userformDto.timezone;
  return newUser;
}

export function getNonProfessionalRoles(roles: UserRole[] | undefined) {
  if (!roles) {
    return [];
  }
  return roles.filter((role) => role != UserRole.ADMIN && role != UserRole.RECEPTIONIST && role != UserRole.SPECIALIST);
}

export { User, UserTableDto, UserFormDto, UserRole };
