import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["aria-expanded"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "initials"
}
const _hoisted_4 = {
  key: 0,
  class: "account-options"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "account-info" }
const _hoisted_7 = ["src", "alt", "onLoad"]
const _hoisted_8 = {
  key: 1,
  class: "initials"
}
const _hoisted_9 = { class: "account-name" }
const _hoisted_10 = { class: "check-container" }
const _hoisted_11 = {
  key: 0,
  class: "icon-circle_check selected-icon"
}

import { ref, watch, onMounted, onUnmounted } from 'vue';
import { AccountSelector } from '@/models/Account';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountSelectorComponent',
  props: {
  accounts: {
    type: Array as () => AccountSelector[],
    required: true,
  },
  currentAccount: {
    type: Object as () => AccountSelector,
    required: true,
  },
},
  emits: ['accountSelected'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const isOpen = ref(false);
const currentAccountImageLoaded = ref(false);
const accountImagesLoaded = ref<Record<string, boolean>>({});
const accountSelectorRef = ref<HTMLElement | null>(null);

const toggleOptions = () => {
  isOpen.value = !isOpen.value;
};

const selectAccount = (account: AccountSelector) => {
  emit('accountSelected', account);
  isOpen.value = false;
};

const getInitials = (name: string): string => {
  const words = name.trim().split(' ');
  const initials = words
    .slice(0, 2)
    .map((word) => word[0].toUpperCase())
    .join('');
  return initials;
};

const onCurrentAccountImageLoad = () => {
  currentAccountImageLoaded.value = true;
};

const onAccountImageLoad = (accountId: string) => {
  accountImagesLoaded.value[accountId] = true;
};

const handleClickOutside = (event: MouseEvent) => {
  if (accountSelectorRef.value && !accountSelectorRef.value.contains(event.target as Node)) {
    isOpen.value = false;
  }
};

watch(
  () => props.accounts,
  (newAccounts) => {
    newAccounts.forEach((account) => {
      if (account.iconUrl) {
        accountImagesLoaded.value[account.idAccount] = false;
      }
    });
  },
  { deep: true, immediate: true },
);

watch(
  () => props.currentAccount,
  () => {
    currentAccountImageLoaded.value = false;
  },
  { deep: true },
);

onMounted(() => {
  currentAccountImageLoaded.value = false;
  props.accounts.forEach((account) => {
    if (account.iconUrl) {
      accountImagesLoaded.value[account.idAccount] = false;
    }
  });
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "accountSelectorRef",
    ref: accountSelectorRef,
    class: "account-selector"
  }, [
    _createElementVNode("button", {
      class: "account-selector-button",
      "aria-expanded": isOpen.value,
      "aria-haspopup": "true",
      onClick: toggleOptions
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["account-circle", { 'initials-background': !__props.currentAccount.iconUrl }])
      }, [
        (__props.currentAccount.iconUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: __props.currentAccount.iconUrl,
              alt: __props.currentAccount.name,
              class: _normalizeClass(["account-icon", { loaded: currentAccountImageLoaded.value }]),
              onLoad: onCurrentAccountImageLoad
            }, null, 42, _hoisted_2))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(getInitials(__props.currentAccount.name)), 1))
      ], 2),
      _createElementVNode("i", {
        class: _normalizeClass({ 'icon-chevron_down': !isOpen.value, 'icon-chevron_up': isOpen.value })
      }, null, 2)
    ], 8, _hoisted_1),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (isOpen.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.accounts, (account) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: account.idAccount
                }, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["account-option", { selected: __props.currentAccount.idAccount === account.idAccount }]),
                    onClick: ($event: any) => (selectAccount(account))
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["account-circle", { 'initials-background': !account.iconUrl }])
                      }, [
                        (account.iconUrl)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: account.iconUrl,
                              alt: account.name,
                              class: _normalizeClass(["account-icon", { loaded: accountImagesLoaded.value[account.idAccount] }]),
                              onLoad: ($event: any) => (onAccountImageLoad(account.idAccount))
                            }, null, 42, _hoisted_7))
                          : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(getInitials(account.name)), 1))
                      ], 2),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(account.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      (__props.currentAccount.idAccount === account.idAccount)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_11))
                        : _createCommentVNode("", true)
                    ])
                  ], 10, _hoisted_5)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 512))
}
}

})