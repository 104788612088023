import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "certificate__container" }
const _hoisted_2 = { class: "certificate__row__two-icons" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "certificate__row__text" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "certificate__row__two-icons" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "certificate__row__text" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "certificate__row__one-icon" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "certificate__row__text" }
const _hoisted_13 = { class: "certificate__row__one-icon" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "certificate__row__text" }
const _hoisted_16 = { class: "certificate__row__one-icon" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "certificate__row__one-icon" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "certificate__row__one-icon" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "certificate__row__text" }

import { ref } from 'vue';
import { version, makeDate } from '../../package.json';
import { buildDate } from '@/buildTimestamp';
import moment from 'moment';
import dateFormat from '@/helpers/DateFormat.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'CertificateCE',
  setup(__props) {

const logoCe = ref(window.location.origin + '/assets/icon/certificate/ce.png');
const logoCeQr = ref(window.location.origin + '/assets/icon/certificate/ce-qr.png');
const logoHealz = ref(window.location.origin + '/assets/icon/certificate/logo-healz.svg');
const logoMaker = ref(window.location.origin + '/assets/icon/certificate/maker.png');
const logoMakerWhite = ref(window.location.origin + '/assets/icon/certificate/maker-white.png');
const logoSerialNumber = ref(window.location.origin + '/assets/icon/certificate/serial-number.png');
const logoInfo = ref(window.location.origin + '/assets/icon/certificate/info.png');
const logoIVD = ref(window.location.origin + '/assets/icon/certificate/ivd.png');
const database = ref(window.location.origin + '/assets/icon/certificate/database.svg');

const ceTextQr1 = ref(process.env.VUE_APP_CE_QR_1);
const ceTextQr2 = ref(process.env.VUE_APP_CE_QR_2);
const ceTextQr3 = ref(process.env.VUE_APP_CE_QR_3);
const ceTextName = ref(process.env.VUE_APP_HEALZ_NAME);
const ceTextAddress = ref(process.env.VUE_APP_HEALZ_ADDRESS);
const ceSerialNumber = ref(process.env.VUE_APP_SERIAL_NUMBER);
const lastBuildDate = moment(dateFormat.utcToUserTimezone(buildDate)).format(dateFormat.getDateSimpleFormat());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: logoCe.value,
        alt: "ce"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(ceTextQr1.value), 1),
        _createElementVNode("p", null, _toDisplayString(ceTextQr2.value), 1),
        _createElementVNode("p", null, _toDisplayString(ceTextQr3.value), 1)
      ]),
      _createElementVNode("img", {
        src: logoCeQr.value,
        alt: "ceqr"
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", {
        src: logoMaker.value,
        alt: "ce"
      }, null, 8, _hoisted_7),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", null, [
          _createElementVNode("span", null, _toDisplayString(ceTextName.value), 1),
          _createTextVNode(" " + _toDisplayString(ceTextAddress.value), 1)
        ])
      ]),
      _createElementVNode("img", {
        src: logoHealz.value,
        alt: "ceqr"
      }, null, 8, _hoisted_9)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("img", {
        src: logoMakerWhite.value,
        alt: "ce"
      }, null, 8, _hoisted_11),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("p", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('certificate-ce.version')), 1),
          _createTextVNode(_toDisplayString(_unref(version)), 1)
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('certificate-ce.make-date')), 1),
          _createTextVNode(" " + _toDisplayString(_unref(makeDate)), 1)
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('certificate-ce.last-version-date')), 1),
          _createTextVNode(" " + _toDisplayString(_unref(lastBuildDate)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("img", {
        src: logoSerialNumber.value,
        alt: "ce"
      }, null, 8, _hoisted_14),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("p", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('certificate-ce.serial-number')), 1),
          _createTextVNode(" " + _toDisplayString(ceSerialNumber.value), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("img", {
        src: logoInfo.value,
        alt: "ce"
      }, null, 8, _hoisted_17)
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("img", {
        src: logoIVD.value,
        alt: "ce"
      }, null, 8, _hoisted_19)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("img", {
        src: database.value,
        alt: "ce",
        class: "database-icon"
      }, null, 8, _hoisted_21),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('certificate-ce.storage')), 1)
      ])
    ])
  ]))
}
}

})