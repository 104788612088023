
import { defineComponent, ref, watch } from 'vue';
import AboutUsModal from './AboutUsModal.vue';
import MenuComponent from './MenuComponent.vue';
import AccountSelectorComponent from './account/AccountSelectorComponent.vue';
import { accountDocumentService } from '@/services/AccountDocumentService';
import { Document } from '@/models/AccountDocument';
import { AccountSelector } from '@/models/Account';
import { useProfileStore } from '@/store/profile.module';

export default defineComponent({
  components: { MenuComponent, AboutUsModal, AccountSelectorComponent },
  emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
  props: {
    model: {
      type: Array,
      required: true,
    },
    layoutMode: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    mobileMenuActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(_props, { emit }) {
    const storeProfile = useProfileStore();
    const accounts = ref<AccountSelector[]>([]);
    const selectedAccount = ref<AccountSelector>();
    const aboutUsModalRef = ref();
    const accountSelectorKey = ref(0);

    const loadAccounts = async () => {
      accounts.value = await Promise.all(storeProfile.userAccounts.map(mapAccount));
      accountSelectorKey.value++;
    };

    const mapAccount = async (account: any): Promise<AccountSelector> => {
      const iconUrl = account.profileImage ? await getFileContentUrlFromS3(account.id, account.profileImage) : '';
      return {
        idAccount: account.id,
        name: account.businessName || `${account.name} ${account.userSurname}`,
        iconUrl,
      };
    };

    const getFileContentUrlFromS3 = async (accountId: string, document: Document): Promise<string> => {
      try {
        const response = await accountDocumentService.getContentURLFromS3(
          accountId,
          document.entityType,
          document.entityId,
          document.id,
        );
        return response.url;
      } catch (error) {
        console.error('Failed to get file content URL from S3:', error);
      }
      return '';
    };

    watch(
      () => storeProfile.userAccounts,
      async (newAccounts) => {
        if (newAccounts && newAccounts.length > 0) {
          await loadAccounts().then(() => {
            selectedAccount.value = accounts.value.find((account) => account.idAccount === storeProfile.accountId);
          });
        }
      },
      { immediate: true, deep: true },
    );

    const onMenuClick = (event: Event) => {
      emit('menu-click', event);
    };

    const onMenuItemClick = (event: Event) => {
      emit('menuitem-click', event);
    };

    const onRootMenuItemClick = (event: Event) => {
      emit('root-menuitem-click', event);
    };

    const onShowAboutUsModal = () => {
      aboutUsModalRef.value.openDialog();
    };

    const onAccountSelected = async (account: AccountSelector) => {
      storeProfile.setCurrentAccountContext(account.idAccount);
      selectedAccount.value = account;
      await loadAccounts();
    };

    return {
      onMenuClick,
      onMenuItemClick,
      onRootMenuItemClick,
      aboutUsModalRef,
      onShowAboutUsModal,
      accounts,
      selectedAccount,
      onAccountSelected,
    };
  },
});
